import { AxiosRequestConfig } from "axios";
import { ENDPOINT } from "constants/endpoint";
import { useUserInfoContext } from "contexts/userInfoContext";
import jwt from "jsonwebtoken";
import { QUICKSIGHT, SESSION_TIMEOUT } from "pages/pageInfo";
import React, { useEffect, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { request } from "util/request";

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

export const QuickSight = () => {
    const { signOut, } = useUserInfoContext();
    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const [data, setData] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    useEffect(()=>{
        if(sessionStorage.getItem("successMessage")){
            setSuccessMessage(sessionStorage.getItem("successMessage"));
            sessionStorage.removeItem("successMessage");
        }
    }, [successMessage]);
    
    useEffect(() => {
        // 表示用データが空で、リクエスト中でもない場合
        // リクエストを投げてデータを取得する
        if(data === null && !isRequesting){
            (async ()=>{
                setIsRequesting(true); // APIの二重コール防止用
    
            // GETリクエスト
            const config: AxiosRequestConfig = {
                method: "get"
            };
            
            await request(ENDPOINT.QUICKSIGHT, config, setErrorMessage)
                .then((res: any) =>{ setData(res.data); setIsRequesting(false); })
                .catch((err: any)=>{
                    // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
                    if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }

                    setData(undefined); // nullにしたいけど、nullにすると無限にリクエストすることになるのでundefined使う
                    setIsRequesting(false); // 必ずsetDataの方が先（dataがnullのまま先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
                    return;
                }
                );
            })();
        }
    });

    // ダッシュボード生成
    useEffect(() => {
        if(data !== null && data !== undefined){
            const containerDiv = document.getElementById("dashboardContainer");
            const options = {
            url: data.EmbedUrl,
            container: containerDiv,
            width:"100%",
            height:"1000px"
            };
            QuickSightEmbedding.embedDashboard(options);
        }
    },[data]);

    const dashboardcontent = !data
        ?(isRequesting
            ?<Spinner animation="border" className="mt-4" style={{width: "5rem", height: "5rem"}}/>
            :null
        )
        :(
            <div id="dashboardContainer"></div>
        );

    // エラーメッセージの生成
    const alert = errorMessage != null
        ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
        : null;


    return (
        <div className="mt-5 ml-5 mr-5">
            <div className="d-flex">
                <h2 className="">{QUICKSIGHT.title}</h2>
            </div>
    
            {alert}
    
            {dashboardcontent}

        </div>
    );
};


