import Amplify from "aws-amplify";
import "bootstrap/dist/css/bootstrap.min.css";
import { Footer } from "components/footer/Footer";
import { Header } from "components/header/Header";
import { PrivateRoute } from "components/PrivateRoute";
import { UserInfoContextProvider } from "contexts/userInfoContext";
import { CompanyEdit } from "pages/CompanyEdit/CompanyEdit";
import { CompanyList } from "pages/CompanyList/CompanyList";
import { CompanyRegister } from "pages/CompanyRegister/CompanyRegister";
import { DrivingHistoryDetail } from "pages/DrivingHistoryDetail/DrivingHistoryDetail";
import { HistoryManagement } from "pages/HistoryManagement/HistoryManagement";
import { IssueNewPassword } from "pages/IssueNewPassword/IssueNewPassword";
import Menu from "pages/Menu/Menu";
import { MoviePlayback } from "pages/MoviePlayback/MoviePlayback";
import * as pg from "pages/pageInfo";
import Profile from "pages/Profile/Profile";
import { QuickSight } from "pages/QuickSight/QuickSight";
import { RecorderEdit } from "pages/RecorderEdit/RecorderEdit";
import { RecorderList } from "pages/RecorderList/RecorderList";
import { RecorderRegister } from "pages/RecorderRegister/RecorderRegister";
import RegisterUserForAdmin from "pages/RegisterUser/RegisterUserForAdmin";
import RegisterUserForManager from "pages/RegisterUser/RegisterUserForManager";
import { RequestNewPassword } from "pages/RequestNewPassword/RequestNewPassword";
import SesstionTimeout from "pages/SesstionTimeout/SesstionTimeout";
import { SignIn } from "pages/SignIn/SignIn";
import { TrackingManagement } from "pages/TrackingManagement/TrackingManagement";
import { TrackingManagementGetfile } from "pages/TrackingManagementGetfile/TrackingManagementGetfile";
import { UserEdit } from "pages/UserEdit/UserEdit";
import { UserList } from "pages/UserList/UserList";
import { UserRegister } from "pages/UserRegister/UserRegister";
import { VehicleEdit } from "pages/VehicleEdit/VehicleEdit";
import { VehicleList } from "pages/VehicleList/VehicleList";
import { VehicleRegister } from "pages/VehicleRegister/VehicleRegister";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

const App = () => (
  <UserInfoContextProvider>
    <BrowserRouter>
      <Header></Header>
      {/* <NavigationBar /> */}
      <Switch>

        {/* ログイン無し */}
        <Route exact path={pg.SIGN_IN.path}><SignIn />
        </Route>
        <Route exact path={pg.REQUEST_NEW_PASSWORD.path}><RequestNewPassword />
        </Route>
        <Route exact path={pg.ISSUE_NEW_PASSWORD.path}><IssueNewPassword />
        </Route>
        <Route exact path={pg.SESSION_TIMEOUT.path}><SesstionTimeout />
        </Route>

        {/* ログイン済み */}
        <PrivateRoute exact path={pg.MENU.path} pageInfo={pg.MENU}><Menu />
        </PrivateRoute>
        <PrivateRoute exact path={pg.PROFILE.path} pageInfo={pg.PROFILE}><Profile />
        </PrivateRoute>
        <PrivateRoute exact path={pg.REGISTER_USER_FOR_ADMIN.path} pageInfo={pg.REGISTER_USER_FOR_ADMIN}><RegisterUserForAdmin />
        </PrivateRoute>
        <PrivateRoute exact path={pg.REGISTER_USER_FOR_MANAGER.path} pageInfo={pg.REGISTER_USER_FOR_MANAGER}><RegisterUserForManager />
        </PrivateRoute>
        <PrivateRoute exact path={pg.COMPANY_LIST.path} pageInfo={pg.COMPANY_LIST}><CompanyList />
        </PrivateRoute>
        <PrivateRoute exact path={pg.COMPANY_REGISTER.path} pageInfo={pg.COMPANY_REGISTER}><CompanyRegister />
        </PrivateRoute>
        <PrivateRoute exact path={pg.COMPANY_EDIT.path} pageInfo={pg.COMPANY_EDIT}><CompanyEdit />
        </PrivateRoute>
        <PrivateRoute exact path={pg.RECORDER_LIST.path} pageInfo={pg.RECORDER_LIST}><RecorderList />
        </PrivateRoute>
        <PrivateRoute exact path={pg.RECORDER_REGISTER.path} pageInfo={pg.RECORDER_REGISTER}><RecorderRegister />
        </PrivateRoute>
        <PrivateRoute exact path={pg.RECORDER_EDIT.path} pageInfo={pg.RECORDER_EDIT}><RecorderEdit />
        </PrivateRoute>
        <PrivateRoute exact path={pg.USER_LIST.path} pageInfo={pg.USER_LIST}><UserList />
        </PrivateRoute>
        <PrivateRoute exact path={pg.USER_REGISTER.path} pageInfo={pg.USER_REGISTER}><UserRegister />
        </PrivateRoute>
        <PrivateRoute exact path={pg.USER_EDIT.path} pageInfo={pg.USER_EDIT}><UserEdit />
        </PrivateRoute>
        <PrivateRoute exact path={pg.VEHICLE_LIST.path} pageInfo={pg.VEHICLE_LIST}><VehicleList />
        </PrivateRoute>
        <PrivateRoute exact path={pg.VEHICLE_REGISTER.path} pageInfo={pg.VEHICLE_REGISTER}><VehicleRegister />
        </PrivateRoute>
        <PrivateRoute exact path={pg.VEHICLE_EDIT.path} pageInfo={pg.VEHICLE_EDIT}><VehicleEdit />
        </PrivateRoute>
        <PrivateRoute exact path={pg.MOVIE_PLAYBACK.path} pageInfo={pg.MOVIE_PLAYBACK}><MoviePlayback />
        </PrivateRoute>
        <PrivateRoute exact path={pg.TRACKING_MANAGEMENT_GETFILE.path} pageInfo={pg.TRACKING_MANAGEMENT_GETFILE}><TrackingManagementGetfile />
        </PrivateRoute>
        <PrivateRoute exact path={pg.HISTORY_MANAGEMENT.path} pageInfo={pg.HISTORY_MANAGEMENT}><HistoryManagement />
        </PrivateRoute>
        <PrivateRoute exact path={pg.DRIVING_HISTORY_DETAIL.path} pageInfo={pg.DRIVING_HISTORY_DETAIL}><DrivingHistoryDetail />
        </PrivateRoute>
        <PrivateRoute exact path={pg.TRACKING_MANAGEMENT.path} pageInfo={pg.TRACKING_MANAGEMENT}><TrackingManagement />
        </PrivateRoute>
        <PrivateRoute exact path={pg.QUICKSIGHT.path} pageInfo={pg.QUICKSIGHT}><QuickSight />
        </PrivateRoute>

      </Switch>
      <Footer></Footer>
    </BrowserRouter>
  </UserInfoContextProvider>
);

export default App;
