import { PageInfo } from "../interfaces/pageInfoInterface";

export const SIGN_IN: PageInfo = {
  path: "/login",
  title: "ログイン",
  allowedRoles: ["unauthorized", "driver", "manager", "admin"],
};

export const MENU: PageInfo = {
  path: "/",
  title: "メニュー",
  allowedRoles: ["driver", "manager", "admin"],
};

export const PROFILE: PageInfo = {
  path: "/user/profile",
  title: "Profile",
  allowedRoles: ["driver", "manager", "admin"],
};

export const REGISTER_USER_FOR_MANAGER: PageInfo = {
  path: "/manager/user/register",
  title: "Register New User",
  allowedRoles: ["manager", "admin"],
};

export const REGISTER_USER_FOR_ADMIN: PageInfo = {
  path: "/admin/user/register",
  title: "Register New User",
  allowedRoles: ["admin"],
};

export const RECORDER_REGISTER: PageInfo = {
  path: "/recorder/register",
  title: "ドラレコ新規登録",
  allowedRoles: ["admin"],
};


export const COMPANY_REGISTER: PageInfo = {
  path: "/company/register",
  title: "顧客新規登録",
  allowedRoles: ["admin"],
};

export const COMPANY_LIST: PageInfo = {
  path: "/company/list",
  title: "顧客一覧",
  allowedRoles: ["admin"],
};

export const USER_LIST: PageInfo = {
  path: "/user/list",
  title: "利用者一覧",
  allowedRoles: ["manager"],
};

export const VEHICLE_LIST: PageInfo = {
  path: "/vehicle/list",
  title: "車両一覧",
  allowedRoles: ["manager"],
};

export const USER_EDIT: PageInfo = {
  path: "/user/update",
  title: "利用者編集",
  allowedRoles: ["manager"],
};

export const VEHICLE_EDIT: PageInfo = {
  path: "/vehicle/update",
  title: "車両編集",
  allowedRoles: ["manager"],
};

export const RECORDER_LIST: PageInfo = {
  path: "/recorder/list",
  title: "ドラレコ一覧",
  allowedRoles: ["admin"],
};

export const RECORDER_EDIT: PageInfo = {
  path: "/recorder/update",
  title: "ドラレコ編集",
  allowedRoles: ["admin"],
};

export const COMPANY_EDIT: PageInfo = {
  path: "/company/update",
  title: "顧客編集",
  allowedRoles: ["admin"],
};

export const TRACKING_MANAGEMENT: PageInfo = {
  path: "/tracking/management",
  title: "動態管理",
  allowedRoles: ["driver", "manager"],
};

export const TRACKING_MANAGEMENT_GETFILE: PageInfo = {
  path: "/tracking/management/getfile",
  title: "動画像取得",
  allowedRoles: ["driver", "manager"],
};

export const MOVIE_PLAYBACK: PageInfo = {
  path: "/movie/playback",
  title: "動画再生",
  allowedRoles: ["driver", "manager", "admin"],
};

export const HISTORY_MANAGEMENT: PageInfo = {
  path: "/history/management",
  title: "履歴管理",
  allowedRoles: ["driver", "manager"],
};

export const QUICKSIGHT: PageInfo = {
  path: "/quicksight",
  title: "統計ダッシュボード",
  allowedRoles: ["driver", "manager"],
};

export const DRIVING_HISTORY_DETAIL: PageInfo = {
  path: "/driving/history/detail",
  title: "走行履歴詳細",
  allowedRoles: ["driver", "manager"],
};

export const USER_REGISTER: PageInfo = {
  path: "/user/register",
  title: "利用者新規登録",
  allowedRoles: ["manager"],
};

export const VEHICLE_REGISTER: PageInfo = {
  path: "/vehicle/register",
  title: "車両新規登録",
  allowedRoles: ["manager"],
};

export const CHANGE_PASSWORD: PageInfo = {
  path: "/changepassword",
  title: "パスワード変更",
  allowedRoles: ["admin", "manager", "driver", "unauthorized"],
};

export const REQUEST_NEW_PASSWORD: PageInfo = {
  path: "/request_new_password",
  title: "パスワード再発行申込",
  allowedRoles: ["admin", "manager", "driver", "unauthorized"],
};

export const ISSUE_NEW_PASSWORD: PageInfo = {
  path: "/request_new_password/:code",
  title: "パスワード再発行",
  allowedRoles: ["admin", "manager", "driver", "unauthorized"],
};

export const SESSION_TIMEOUT: PageInfo = {
  path: "/timeout",
  title: "タイムアウト",
  allowedRoles: ["admin", "manager", "driver", "unauthorized"],
};