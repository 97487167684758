const BASE = process.env.REACT_APP_API_HOST + "";

export const ENDPOINT = {
  USER_LIST: `${BASE}/user/list`,
  USER_REGISTER: `${BASE}/user/register`,
  USER_UPDATE: `${BASE}/user/update`,
  USER_DELETE: `${BASE}/user/delete`,
  USER_DETAIL_INFO_GET: `${BASE}/user/detail/info/get`,
  VEHICLE_LIST: `${BASE}/vehicle/list`,
  RECORDER_LIST: `${BASE}/recorder/list`,
  COMPANY_LIST: `${BASE}/company/list`,
  COMPANY_REGISTER: `${BASE}/company/register`,
  COMPANY_UPDATE: `${BASE}/company/update`,
  COMPANY_DELETE: `${BASE}/company/delete`,
  COMPANY_DETAIL_INFO_GET: `${BASE}/company/detail/info/get`,
  VEHICLE_REGISTER_INFO_GET: `${BASE}/vehicle/register/info/get`,
  VEHICLE_REGISTER: `${BASE}/vehicle/register`,
  VEHICLE_UPDATE: `${BASE}/vehicle/update`,
  VEHICLE_DELETE: `${BASE}/vehicle/delete`,
  VEHICLE_DETAIL_INFO_GET: `${BASE}/vehicle/detail/info/get`,
  TRACKING_MANAGEMENT_GETFILE_REGISTER: `${BASE}/tracking/management/getfile/register`,
  TRACKING_MANAGEMENT_GETFILE_SEARCH: `${BASE}/tracking/management/getfile/search`,
  RECORDER_REGISTER_INFO_GET: `${BASE}/recorder/register/info/get`,
  RECORDER_REGISTER: `${BASE}/recorder/register`,
  RECORDER_UPDATE: `${BASE}/recorder/update`,
  RECORDER_DELETE: `${BASE}/recorder/delete`,
  RECORDER_DETAIL_INFO_GET: `${BASE}/recorder/detail/info/get`,
  MOVIE_INFO_GET: `${BASE}/movie/info/get`,
  MOVIE_PLAYLIST_GET: `${BASE}/movie/playback/list/get`,
  DRIVING_HISTORY_SEARCH: `${BASE}/driving/history/search`,
  DRIVING_HISTORY_DETAIL_INFO_GET: `${BASE}/driving/history/detail/info/get`,
  TRACKING_MANAGEMENT_GET: `${BASE}/tracking/management/get`,
  OFFER_UPDATE_PASSWORD: `${BASE}/login/password/update/offer`,
  QUICKSIGHT: `${BASE}/quicksight`,
  UPDATE_PASSWORD: `${BASE}/login/password/update`,
};