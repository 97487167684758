import { AxiosRequestConfig } from "axios";
import { MyDatePicker, MySelect } from "components/parts/InputRow";
import Pagination from "components/parts/Pagination";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { useUserInfoContext } from "contexts/userInfoContext";
import jwt from "jsonwebtoken";
import { DRIVING_HISTORY_DETAIL, HISTORY_MANAGEMENT, SESSION_TIMEOUT } from "pages/pageInfo";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { request } from "util/request";

interface State {
  "vehicleId": string,
  "driveDate": Date | null, // date: 日付指定有り、null: 日付指定無し
  "inputDisabled": boolean,
}

type COLS = "no" | "drive_date" | "vehicle_id" | "vehicle_name" | "user_id" | "user_name" | "departure_datetime" | "return_datetime" |
  "total_mileage" | "total_driving_time" | "suddenly_accel_count" | "suddenly_brake_count" | "suddenly_turn_count" |
  "speed_over_count" | "shock_count" | "panic_count";

export const HistoryManagement = () => {
  const now = new Date();
  const { signOut, } = useUserInfoContext();
  const [isRequestingVehicleList, setIsRequestingVehicleList] = useState<boolean>(false);
  const [isRequestingDrvHistory, setIsRequestingDrvHistory] = useState<boolean>(false);
  const [vehicleData, setVehicleData] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
  const [data, setData] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
  const [validated, setValidated] = useState(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [sortId, setSort] = useState<COLS>("drive_date");
  const [order, setOrder] = useState<string>("desc");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [state, setState] = useState<State>({
    "vehicleId": "",
    "driveDate": new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1), // 現在日付の1日前
    "inputDisabled": false,
  });
  const history = useHistory();

  //  画面遷移時にURLのパラメータとstate値を同期させたかどうか
  // true: 同期済み, false: 未同期
  const [urlSynced, setUrlSynced] = useState<boolean>(false);

  const handlePageChange = (page: number) => {
    setActivePage(page);
    setData(null);
  };

  const handleOnClickCol = (e: React.MouseEvent<HTMLInputElement>, col: COLS) => {

    // ソート順の決定
    const _order = sortId !== col // "現ソートカラム != クリックされたカラム" の場合
      ? "asc" // asc 固定
      : order === "desc" // "現ソートカラム == クリックされたカラム" の場合
        ? "asc" // desc だった場合、asc へ
        : "desc"; // asc だった場合、desc へ

    setOrder(_order);
    setSort(col);
    setData(null);
  };

  // 日付のフォーマット
  const zeroPadding = (num: number, len: number) => (String(num).padStart(len, "0"));
  const date = state.driveDate;
  const fomatedDriveDate = date ? zeroPadding(date.getFullYear(), 4) + "/" + zeroPadding(date.getMonth() + 1, 2) + "/" + zeroPadding(date.getDate(), 2) : null;


  // ページ遷移してきた際のURLのdateとstateを合わせる
  useEffect(() => {
    if(!urlSynced && vehicleData !== null){

      // 以降、日付の同期
      const queryDate = queryString.parse(history.location.search)["date"] as string;
      let _date = null;

      // dateが指定されてない場合、1日前にする
      if(queryDate === undefined){
        const now = new Date();
        _date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      }

      // nullの場合、nullにする
      else if(queryDate === "null"){  // パラメータとして指定されているnullなので文字列のnullで比較する
        _date = null;
      }

      // 日付有の場合、日付にする
      else {
        _date = new Date(queryDate);
      }

      // 以降、車両IDの同期
      const queryVehicleId = queryString.parse(history.location.search)["vehicle_id"] as string;
      let _vehicleId = null;

      // idが指定されてない場合、空文字列にする（stateの初期値が""であるため、それに合わせる）
      if(queryVehicleId === undefined){
        _vehicleId = "";
      }

      // nullの場合、空文字列にする
      else if(queryVehicleId === "null"){  // パラメータとして指定されているnullなので文字列のnullで比較する
        _vehicleId = "";
      }

      // id有の場合、idにする
      else {
        _vehicleId = queryVehicleId;
      }

      setState({...state, driveDate: _date, vehicleId: _vehicleId});
      setUrlSynced(true);
    }
  }, [urlSynced, history.location.search, state, vehicleData]);


  // カレンダー、選択車両の値とURLのパラメータを連動させる
  useEffect(() => {
    if(urlSynced === false) return;

    const date = state.driveDate;
    const _date = date ? zeroPadding(date.getFullYear(), 4) + "/" + zeroPadding(date.getMonth() + 1, 2) + "/" + zeroPadding(date.getDate(), 2) : null;

    const vehicleId = state.vehicleId;
    const _vehicleId = vehicleId !== "" ? vehicleId : null;

    history.replace(HISTORY_MANAGEMENT.path + `?date=${_date}&vehicle_id=${_vehicleId}`);
  }, [urlSynced, history, state]);


  useEffect(() => {
    document.title = HISTORY_MANAGEMENT.title;

    if(urlSynced === false) return;

    // 走行履歴が空で、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if (data === null && !isRequestingDrvHistory) {
      (async () => {
        setIsRequestingDrvHistory(true); // APIの二重コール防止用

        // 走行履歴のGETリクエスト
        const config: AxiosRequestConfig = {
          method: "post",
          data: {
            vehicle_id: state.vehicleId ? parseInt(state.vehicleId) : null,
            drive_date: fomatedDriveDate,
            page: activePage,
            sort_item: sortId,
            sort_order: order
          },
        };

        await request(ENDPOINT.DRIVING_HISTORY_SEARCH, config, setErrorMessage)
          .then((res: any) => { setData(res.data); setIsRequestingDrvHistory(false); })
          .catch((err: any) => {
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if (err instanceof jwt.JsonWebTokenError) { signOut(SESSION_TIMEOUT.path); return; }

            setData(undefined); // nullにしたいけど、nullにすると無限にリクエストすることになるのでundefined使う
            setIsRequestingDrvHistory(false); // 必ずsetDataの方が先（dataがnullのまま先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
            return;
          }
          );
      })();
    }
  }, [urlSynced, activePage, data, fomatedDriveDate, isRequestingDrvHistory, order, signOut, sortId, state.vehicleId]);

  useEffect(() => {
    // 車両一覧が空で、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if (vehicleData === null && !isRequestingVehicleList) {
      (async () => {
        setIsRequestingVehicleList(true); // APIの二重コール防止用

        // 車両一覧のGETリクエスト
        const vehicleConfig: AxiosRequestConfig = {
          method: "post",
          data: {
            sort_item: "vehicle_name",
            sort_order: "asc"
          },
        };

        await request(ENDPOINT.VEHICLE_LIST, vehicleConfig, setErrorMessage)
          .then((res: any) => { setVehicleData(res.data); setIsRequestingVehicleList(false); })
          .catch((err: any) => {
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if (err instanceof jwt.JsonWebTokenError) { signOut(SESSION_TIMEOUT.path); return; }

            setVehicleData(undefined); // nullにしたいけど、nullにすると無限にリクエストすることになるのでundefined使う
            setIsRequestingVehicleList(false); // 必ずsetVehicleDataの方が先（vehicleDataがnullのまま先にisRequestingVehicleListをfalseにすると2回目のリクエストを投げるので。。）
            return;
          }
          );
      })();
    }

  }, [isRequestingVehicleList, signOut, vehicleData]);

  // submitボタンのハンドラ
  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      await setValidated(true);
      return;
    }

    setErrorMessage(null);
    setData(null);
  };

  // 入力欄に入力した時のハンドラ
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, target: string) => {
    // 空文字列はnullに変換する
    setState({ ...state, [target]: event.target.value });
  };

  // 入力欄にdriveDateを入力した時のハンドラ
  const handleOnChangeDriveDate = (date: any, target: string) => {
    setState({ ...state, [target]: date });
  };

  // ヘッダ部分の生成
  const cols: { name: string, id: COLS }[] = [
    { "name": "No.", "id": "no" },
    { "name": "運行日", "id": "drive_date" },
    { "name": "車両名", "id": "vehicle_name" },
    { "name": "ドライバー", "id": "user_name" },
    { "name": "出庫時刻", "id": "departure_datetime" },
    { "name": "帰庫時刻", "id": "return_datetime" },
    { "name": "総走行距離", "id": "total_mileage" },
    { "name": "総走行時間", "id": "total_driving_time" },
    { "name": "急加速回数", "id": "suddenly_accel_count" },
    { "name": "急減速回数", "id": "suddenly_brake_count" },
    { "name": "急ハンドル回数", "id": "suddenly_turn_count" },
    { "name": "速度超過回数", "id": "speed_over_count" },
    { "name": "強衝撃回数", "id": "shock_count" },
    { "name": "緊急通報回数", "id": "panic_count" },
  ];

  const listHeader = cols.map((col, index) => {
    const _style = sortId === col.id ? "btn-primary list-th" : "btn-light list-th"; // クリックされているカラムの場合、色を付ける
    const _order = sortId === col.id ? (order === "asc" ? "▲" : "▼") : ""; // クリックされているカラムの場合、ソート文字を付ける
    const _onclick = !isRequestingDrvHistory // リクエストを投げている間は反応しないようにする
      ? (e: any) => { handleOnClickCol(e, col.id); }
      : () => { };

    const style_for_id = index === 0 ? { width: "65px" } : {}; // No列だけサイズ指定する
    if (index === 0) {
      // No列はソートしない
      return <th key={col.id} className="btn-light list-th" style={style_for_id} > {col.name}</th>;
    }
    return <th key={col.id} className={_style} onClick={_onclick}>{col.name} {_order}</th>;
  });

  // ドロップダウン表示用のレスポンスを取得できていない ＆ リクエスト中でもない ＆ エラーメッセージが入っている場合、
  //  初期情報の取得でエラー終了しているのでエラーメッセージだけ出す
  if (!vehicleData && !isRequestingVehicleList && errorMessage) {
    return (
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{HISTORY_MANAGEMENT.title}</h2>
        </div>
        <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
      </div>
    );
  }

  // ドロップダウン表示用のレスポンスを取得できていない場合、Loadingを表示
  if (!vehicleData) {
    return (
      <div className="mt-5 ml-5 mr-5">
        <Spinner animation="border" className="ml-4 mt-4" style={{ width: "5rem", height: "5rem" }} />
      </div>
    );
  }

  // 車両リストの生成
  const vehicles = vehicleData.values.map((item: any) => {
    return <option key={item.vehicle_id} value={item.vehicle_id}>{item.vehicle_name}</option>;
  });

  // 車両名
  const vehicleId = {
    label: "車両名",
    attrName: "vehicleId",
    required: false,
    value: state.vehicleId,
    listDom: vehicles,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => { handleOnChange(e, "vehicleId"); },
  };

  // 運行日
  const driveDate = {
    label: "運行日",
    startDate: state.driveDate,
    dateFormat: "yyyy/MM/dd",
    showTimeInput: false,
    timeInputLabel: "",
    maxDate: new Date(),
    isClearable: true,
    disabled: state.inputDisabled,
    onChange: (e: any) => { handleOnChangeDriveDate(e, "driveDate"); },
  };

  // ページネーション部分の生成（レスポンスデータが無い間は非表示）
  const pagination = !data
    ? null
    : (
      <Pagination
        activePage={data.page}
        itemsCountPerPage={data.count}
        totalItemsCount={data.total}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
    );

  // リスト部分の生成
  const list = !data
    ? (isRequestingDrvHistory
      // レスポンスデータ無しでリクエスト中の場合、Loading表示
      ? <Spinner animation="border" className="mt-4" style={{ width: "5rem", height: "5rem" }} />

      // レスポンスデータ無しでリクエスト中でもない場合、何も表示しない（エラー時用）
      : null
    )
    : ( // レスポンスデータが有る場合はそれを表示する
      <>
        <Container fluid>
          <Row>
            <Col xs={2} className="pt-2 border border-top-0 border-left-0 border-bottom-0">
              <div>
                <h5>走行履歴検索</h5>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  {/* 車両名 */}
                  <MySelect {...vehicleId}></MySelect>
                  {/* 運行日 */}
                  <MyDatePicker {...driveDate}></MyDatePicker>

                  <div className="text-right">
                    <Button type="submit" className="d-block my-4 ml-auto px-5" disabled={isRequestingVehicleList}>検索</Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col>
              <div className="d-flex">
                <h2>走行履歴一覧</h2>
              </div>
              <div className="table-responsive mt-4" >
                <h5>総件数：{data.total}</h5>
                <div className="scroll">
                  <table className="table table-striped" style={{ tableLayout: "fixed" }}>
                    <thead>
                      <tr>
                        {listHeader}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data.values.map((item: any, index: any) => {
                          return (
                            <React.Fragment key={item.drive_date + item.vehicle_id}>
                              <tr className="list-page-tr">
                                <td className="link-td" style={{padding: "0", height: "0"}}>
                                  <Link to={`${DRIVING_HISTORY_DETAIL.path}?source=01&vehicle_id=${item.vehicle_id}&driver_id=${item.driver_id}&drive_date=${item.drive_date}&search_vehicle_id=${state.vehicleId}&search_drive_date=${fomatedDriveDate ? fomatedDriveDate : ""}&page=${data.page}&sort_item=${data.sort_item}&sort_order=${data.sort_order}`} style={{height: "100%", padding: "10.5px", display: "block"}}>
                                    {/* 項番、ページに応じて項番を計算 */}
                                    {index + 1 + ((data.page - 1) * data.count)}
                                  </Link>
                                </td>
                                <td>{item.drive_date}</td>
                                <td>{item.vehicle_name}</td>
                                <td>{item.driver_name}</td>
                                <td>{String(item.departure_datetime).substr(0, 10)}<br />{String(item.departure_datetime).substr(11)}</td>
                                <td>{String(item.return_datetime).substr(0, 10)}<br />{String(item.return_datetime).substr(11)}</td>
                                <td>{item.total_mileage}Km</td>
                                <td>{item.total_driving_time}</td>
                                <td>{item.suddenly_accel_count}</td>
                                <td>{item.suddenly_brake_count}</td>
                                <td>{item.suddenly_turn_count}</td>
                                <td>{item.speed_over_count}</td>
                                <td>{item.shock_count}</td>
                                <td>{item.panic_count}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>

              {pagination}
            </Col>
          </Row>
        </Container>
      </>
    );

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  return (
    <div className="mt-5 ml-2 mr-2">

      {alert}

      {list}

    </div>
  );
};